.form_create_org {
  form {
    margin-top: 30px;
    margin-bottom: 40px;

    display: flex;
    gap: 10px;
    flex-direction: column;
    width: 100%;
  }
}
