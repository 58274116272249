.sidebar {
  display: flex;
  flex-direction: column;
  width: 367px;
  min-width: 270px;
  border-right: 1px solid #dadada;
  height: 100vh;
  padding: 20px 16px;
  gap: 60px;
  justify-content: space-between;
  z-index: 10;
  overflow: scroll;
  overflow-x: hidden;
  overflow: auto;
  background-color: white;
  transition: all 0.35s ease-out;

  @media only screen and (max-width: 1089px) {
    position: absolute;
    top: 0;
    left: -367px;
    background: #ffffff;
  }
  &.open {
    @media only screen and (max-width: 1089px) {
      left: 0;
      width: 312px;
      border-radius: 0 12px 12px 0;
    }
  }
  & > .sidebar__group {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > .logo__container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      margin-bottom: 40px;
      width: 100%;
      // margin-top: 20px;
      & > .close_sidebar_trigger {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #e8e8e8;
        cursor: pointer;
        transition: all 300ms ease;
        width: 45px;
        height: 40px;
        border-radius: 50% !important;
        // margin-top: 10px;

        & > svg {
          stroke: #0f1e42;
          width: 12px;
        }
      }
      a {
        width: 100%;
        font-weight: 600;
        font-size: 24px;
        color: #667085;
        // margin-top: 20px;
      }
    }
  }
}

.sidebar-logo {
  width: 135px;
  // padding: 0 12px;
}
