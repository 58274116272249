.form_input {
  text-align: left;
  margin-bottom: 18px;
  label {
    font-weight: 400;
    font-size: 14px;
    color: #101828;
  }
  input {
    margin-top: 6px;
  }
  p {
    color: red;
    font-size: 13px;
    margin-top: 3px;
  }
}
