.menu {
  display: flex;
 
  .avatar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    cursor: pointer;
  }
  .profile__avatar {
    cursor: pointer !important;
  }
}
.menu_main {
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
    margin-top: 10px;
    border: 1px solid #e4e7ec;
    box-shadow: 0px 12px 16px rgba(16, 24, 40, 0.08),
      0px 6px 4px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
    border: 1px solid #A4DDF8;
    box-shadow: 0px 12px 16px rgba(203, 240, 244, 0.45);
    border-radius: 8px;
    ul {
      li {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #177C9A;
        margin-bottom: 10px;
        // padding: 4px 20px;
        cursor: pointer;
        &:hover {
          background-color: #fafafa !important;
          border-radius: 10px;
        }
      }
    }
  }
  .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters {
    background-color: white !important;
    // &:hover {
    //   background-color: red !important;
    // }
  }
  .MuiList-root.MuiList-padding.MuiMenu-list {
    width: 193px !important;
    margin-top: 5px;
  }
  .profileIcon{
    width: 18px;
    height: 22px;
    color: #5F6368;    
  }
}
