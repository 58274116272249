@use "./components";

.image_table {
  .string_column {
    font-weight: 400;
    font-size: 14px;
    color: #667085;
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .image_icon {
    display: block;
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
    }
    overflow: hidden;
    background-color: #eee;
  }
  .group-enable {
    background: #d1fadf;
    color: #027a48;
    p {
      background-color: #027a48;
    }
  }
  .group-not-enable {
    background: #fee4e2;
    color: #f04438;
    p {
      background-color: #f04438;
    }
  }

  .image_group_column {
    text-align: center;
    &-images {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      column-gap: 10px;
      &-item {
        width: 29px;
        height: 29px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          background-color: #ffffff;
        }
      }
      .other-images {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        background-color: #d1fadf;
        color: #027a48;
        border-radius: 50%;
        width: 34px;
        height: 34px;
        margin-left: 5px;
      }
    }

    &_users {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #667085;
    }
  }
  .image_column_tags {
    display: flex;
    align-items: center;
    column-gap: 5px;
    overflow: auto;
    .tag {
      white-space: nowrap;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .title_column > .title {
    margin-left: 0 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 50px;
  }
}

.table_action {
  min-width: 120px;
  max-width: 120px;
}
.table_name {
  max-width: calc((100% - (80px + 70px + 25px + 120px + 38px + 34px * 6)) / 4);
  width: calc((100% - (80px + 70px + 25px + 120px + 38px + 34px * 6)) / 4) !important;
  white-space: nowrap;
}
.table_status {
  min-width: 80px;
  max-width: 80px;
}
.table_date {
  min-width: 70px;
  max-width: 70px;
}
