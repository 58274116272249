.invoice {
  display: flex;
  align-items: center;
  gap: 12px;
  img {
    width: 30px;
    height: 35px;
  }
  p {
    color: var(--font, #344054);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .status {
    display: flex;
    padding: 4px 14px;
    align-items: flex-start;
    gap: 10px;

    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .paid {
    border-radius: 49px;
    border: 1px solid var(--primary-colors-success-500, #12b76a);
    background: #bcf6db;
  }
  .not_paid {
    border-radius: 49px;
    border: 1px solid var(--primary-colors-error-500, #f04438);
    background: #ffdbd9;
  }
  .pending {
    border-radius: 49px;
    border: 1px solid var(--primary-colors-warning-500, #f79009);
    background: #ffddb1;
  }
}

.billing_admin {
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    display: flex;
    width: 34px;
    height: 34px;
    justify-content: center;
    align-items: center;
  }
  p {
    color: #848484;
    font-size: 12.523px;
    font-style: normal;
    font-weight: 400;
  }
  .name {
    color: var(--font, #344054);
    font-size: 14.923px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: -3px;
  }
}

.billing_actions {
  display: flex;
  align-items: center;
  gap: 30px;
  .option_icon {
    cursor: pointer;
  }
  button {
    display: flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    color: var(--prim-blue, #0188f7);

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    background-color: #ffff;
    border-radius: 8px;
    border: 1px solid var(--prim-blue, #0188f7);
    cursor: pointer;

    &:hover {
      background: var(--primary-colors-primary-100, #e5f4fd);
    }
  }
}
