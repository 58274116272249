.create-plan-drawer {
  .drawer-head {
    img {
      float: right;
      cursor: pointer;
    }
  }
  .title-drawer {
    margin-bottom: 33px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .price-total {
      color: var(--Primary-Colors-Primary-800, #177c9a);
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 25.113px; /* 89.688% */
    }
    .title {
      color: var(--Primary-Colors-Primary-800, #177c9a);
      font-size: 16.144px;
      font-style: normal;
      font-weight: 600;
      line-height: 25.113px; /* 155.556% */
    }
    .subtitle {
      color: var(--Gray-600, #475467);
      font-size: 12.556px;
      font-style: normal;
      font-weight: 400;
      line-height: 17.938px; /* 142.857% */
    }
  }
  .image-upload {
    margin-bottom: 28px;
  }
  .plan-visibility {
    margin-bottom: 16px;
  }

  .actions {
    display: flex;
    width: 100%;
    gap: 20px;
    align-items: center;
    justify-content: center;

    .btn-cancel {
      display: flex;
      height: 40px;
      justify-content: center;
      align-items: center;
      gap: 7.175px;
      flex: 1 0 0;
      border-radius: 7.175px;
      border: 0.897px solid var(--Prim-Blue, #0188f7);
      background: var(--Base-White, #fff);
      box-shadow: 0px 0.897px 1.794px 0px rgba(16, 24, 40, 0.05);
      color: var(--Prim-Blue, #0188f7);
      font-size: 14.35px;
      font-style: normal;
      font-weight: 600;
      line-height: 21.525px; /* 150% */
      max-width: 160px;
    }
    .btn-publish {
      max-width: 160px;
      display: flex;
      // padding: 8.969px 16.144px;
      height: 40px;
      justify-content: center;
      align-items: center;
      gap: 7.175px;
      flex: 1 0 0;
      border-radius: 7.175px;
      border: 0.897px solid var(--Prim-Blue, #0188f7);
      color: var(--Base-White, #fff);
      box-shadow: 0px 0.897px 1.794px 0px rgba(16, 24, 40, 0.05);
      background: var(--Prim-Blue, #0188f7);
      font-size: 14.35px;
      font-style: normal;
      font-weight: 600;
      line-height: 21.525px; /* 150% */
    }
  }
  .s_input {
    width: 100%;
  }
  .divider-text {
    font-size: 14px;
    color: #a0a0a0;
  }
  // .top-name-desc-fields,
  // .grid-items-2 {
  //   display: grid;
  //   grid-template-columns: repeat(2, 1fr);
  //   grid-gap: 30px;
  // }
  // .grid-plan-items {
  //   display: flex;
  //   width: 100%;
  //   gap: 40px;
  //   .left-items {
  //     width: 100%;
  //   }
  //   .right-items {
  //     width: 100%;
  //   }
  // }
}
