.header {
  display: flex;
  height: 85px !important;
  padding: 21px 30px;
  width: 100% !important;
  justify-content: space-between;
  align-items: center;
  column-gap: 41px;
  background-color: #f8ffff;
  box-shadow: 0px 2px 19px rgba(205, 236, 236, 0.54);

  @media only screen and (max-width: 1089px) {
    padding: 12px 20px;
  }

  & > .header__group {
    display: flex;
    gap: 18px;
    align-items: center;
    @media only screen and (max-width: 1089px) {
      // flex-grow: 1;
      justify-content: space-between;
    }
    .search_header {
      width: 320px;
    }
  }
}

.header_admin {
  display: flex;
  padding: 16px 30px;
  width: 100%;
  // height: 60px;
  // border-bottom: 1px solid #dadada;
  border-bottom: 1px solid #f1f1f1;

  justify-content: space-between;
  background-color: white !important;
  @media only screen and (max-width: 1089px) {
    padding: 12px 20px;
  }

  & > .header__group {
    display: flex;
    gap: 18px;
    align-items: center;
    @media only screen and (max-width: 1089px) {
      flex-grow: 1;
      justify-content: space-between;
    }

    .admin_page_title {
      font-weight: 500;
      font-size: 18px;
      color: #667085;
    }
  }
}

#hideHeader {
  top: -85px;
}

@media only screen and (max-width: 425px) {
  .header {
    .header__group__logo {
      display: none;
    }
  }
}
