.ant-select-show-search {
  z-index: 10;
  min-height: 44px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  border: none;
  margin-bottom: 20px;

  & > div {
    min-height: 44px;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
  }
}
.select {
  height: 44px;
  width: 100% !important;
  .ant-select-selector {
    height: 44px !important;
    display: flex;
    align-items: center;
    width: 100% !important;
  }
  .ant-select-selection-search-input {
    height: 100% !important;
  }
}

.error_txt {
  color: red;
  font-size: 11px;
  margin-bottom: 12px;
}
