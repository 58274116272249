.s_input {
  label {
    color: var(--Gray-700, #344054);
    font-size: 12.556px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.938px; /* 142.857% */
  }
  .input_antd {
    height: 44px;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    &::placeholder {
      font-weight: 300;
      font-size: 15px;
      color: #d0d5dd;
    }
  }
  .ant-select-show-search {
    z-index: 10;
    height: 44px;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    border: none;
    & > div {
      height: 44px;
      background: #ffffff;
      border: 1px solid #d0d5dd;
      border-radius: 8px;
    }
  }
  .ant-select-dropdown.css-dev-only-do-not-override-ixblex.ant-select-dropdown-empty.ant-select-dropdown-placement-topLeft {
    z-index: 10 !important;
  }
}
.form {
  .error_txt {
    color: red;
    font-size: 11px;
    margin-bottom: 12px;
  }
}
