// .form_login {
//   text-align: center;
//   display: flex;
//   flex-direction: column;
//   .login_forgot_pwd {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     margin: 5px 0 20px 0;
//     & > span {
//       display: flex;
//       gap: 8px;
//       font-weight: 400;
//       font-size: 14px;
//       color: #717171;
//       align-items: center;
//       & > input {
//         background: #ffffff;
//         border: 1px solid #e4e7ec;
//         border-radius: 4px;
//         width: 16px;
//         height: 16px;
//       }
//     }
//     & > a {
//       font-weight: 500;
//       font-size: 14px;
//       color: #667085;
//     }
//   }
//   .login_footer {
//     margin-top: 20px;
//     font-weight: 400;
//     font-size: 16px;
//     color: #999999;
//     & > a {
//       color: #667085;
//       font-weight: 500;
//     }
//   }
//   .error {
//     color: red;
//     text-align: center;
//     font-size: '12px';
//     margin-top: 10px;
//     font-weight: 400;
//   }
// }

// .oauth_login {
//   width: 100%;
//   margin-top: 24px;
//   .btn {
//     width: 100%;
//     height: 52px;
//     margin-bottom: 10px;
//     position: relative;
//     cursor: pointer;
//     border-radius: 8px;
//     display: flex;
//     align-items: center;
//     border: 1px solid #c2c8d0;
//     color: #2d333a;
//     padding: 0 8px 0 52px;
//     transition: all 0.4s;
//     &:hover {
//       box-shadow: inset 0 0 0 150px rgba(0, 0, 0, 0.042);
//     }
//     &_icon {
//       height: 24px;
//       width: 24px;
//       position: absolute;
//       left: 26px;
//       top: 50%;
//       transform: translateX(-50%) translateY(-50%);
//     }
//   }
// }

// .access-alternative {
//   width: 100%;
//   display: flex;
//   flex-direction: row;
//   text-transform: uppercase;
//   border: none;
//   font-size: 12px;
//   font-weight: 500;
//   margin: 0;
//   padding: 24px 0 0;
//   span {
//     text-align: center;
//     flex: 0.2 0 auto;
//     margin: 0;
//     color: #999999;
//   }
//   &::after {
//     content: '';
//     border-bottom: 1px solid #c2c8d0;
//     flex: 1 0 auto;
//     height: 0.5em;
//     margin: 0;
//   }
//   &::before {
//     content: '';
//     border-bottom: 1px solid #c2c8d0;
//     flex: 1 0 auto;
//     height: 0.5em;
//     margin: 0;
//   }
// }

// .none {
//   display: none;
// }

.login-container {
  width: 100%;
  display: flex;
  gap: 22px;

  // left
  .login-left {
    padding: 42px 0;
    width: 750px;
    position: relative;

    .login-left-bg {
      width: 100%;
      height: 75%;
      background: #ebfeff;
      border-radius: 0px 40px 40px 0px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -999;
    }

    .login-left-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .login-left-content-logo {
        // width: 55%;
      }

      .login-left-content-info {
        display: flex;
        justify-content: center;
        text-align: center;
        margin-top: 55px;
        margin-bottom: 135px;

        .login-left-content-message {
          width: 55%;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #177c9a;

          span {
            color: #0164fd;
          }
        }
      }

      .login-left-content-bg-img {
        width: 80%;
        animation: floatImage 6s ease-in-out infinite;
      }
    }

    .footer-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #a5b0bb;
    }
  }

  // right
  .login-right {
    padding: 22px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .login-right-shape {
      position: absolute;
      top: 0;
      right: 0;
      width: 220px;
    }

    .login-right-logo-container {
      display: flex;
      justify-content: center;
    }

    .login-right-logo {
      padding: 42px 0;
      display: none;
    }

    .form_login {
      text-align: center;
      display: flex;
      flex-direction: column;
      .login_forgot_pwd {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 5px 0 20px 0;
        & > span {
          display: flex;
          gap: 8px;
          font-weight: 400;
          font-size: 14px;
          color: #717171;
          align-items: center;
          & > input {
            background: #ffffff;
            border: 1px solid #e4e7ec;
            border-radius: 4px;
            width: 16px;
            height: 16px;
          }
        }
        & > a {
          font-weight: 500;
          font-size: 14px;
          color: #667085;
        }
      }

      // .login_footer {
      //   margin-top: 20px;
      //   font-weight: 400;
      //   font-size: 16px;
      //   color: #999999;
      //   & > a {
      //     color: #667085;
      //     font-weight: 500;
      //   }
      // }

      .error {
        color: red;
        text-align: center;
        font-size: "12px";
        margin-top: 10px;
        font-weight: 400;
      }

      .oauth_login {
        width: 100%;
        margin-top: 24px;
        display: flex;
        justify-content: center;

        .btn {
          width: 220px;
          height: 52px;
          margin-bottom: 10px;
          position: relative;
          cursor: pointer;
          border-radius: 8px;
          display: flex;
          align-items: center;
          border: 1px solid #c2c8d0;
          color: #2d333a;
          padding: 0 8px 0 52px;
          transition: all 0.4s;
          &:hover {
            box-shadow: inset 0 0 0 150px rgba(0, 0, 0, 0.042);
          }
          &_icon {
            height: 24px;
            width: 24px;
            position: absolute;
            left: 26px;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
          }
        }
      }

      .access-alternative {
        width: 100%;
        display: flex;
        flex-direction: row;
        text-transform: uppercase;
        border: none;
        font-size: 12px;
        font-weight: 500;
        margin: 0;
        padding: 24px 0 0;
        span {
          text-align: center;
          flex: 0.2 0 auto;
          margin: 0;
          color: #999999;
        }
        &::after {
          content: "";
          border-bottom: 1px solid #c2c8d0;
          flex: 1 0 auto;
          height: 0.5em;
          margin: 0;
        }
        &::before {
          content: "";
          border-bottom: 1px solid #c2c8d0;
          flex: 1 0 auto;
          height: 0.5em;
          margin: 0;
        }
      }
    }
  }

  .login-info-left {
    text-align: center;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .login-info {
    text-align: center;
  }

  .login-title {
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    color: #177c9a;
  }

  .login-subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #999999;
    margin-bottom: 18px;
    margin-top: 14px;
  }

  .input {
    outline: none;
    display: grid;
    width: 507px;
    border: 1px solid #a4ddf8;
  }

  .input-validation-error {
    border: 1px solid #ff4d4f !important;
  }

  .forget-password {
    font-size: 14px;
    color: #667085;
  }

  .btn_auth {
    background-color: #0188f7;
  }

  .login_footer {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #999999;
    margin-top: 20px;

    .go-to {
      color: #0188f7;
    }
  }
}

@keyframes floatImage {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2.4rem);
  }
  100% {
    transform: translateY(0);
  }
}

@media only screen and (max-width: 1920px) {
  .login-container {
    .login-left {
      width: 700px;
    }

    .input {
      width: 507px;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .login-container {
    .login-left {
      width: 700px;
    }

    .input {
      width: 507px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .login-container {
    .login-left {
      display: none;
    }

    .login-right {
      width: 100%;

      .login-right-logo {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .login-container {
    .login-info {
      width: 65%;
    }

    .input {
      width: 100%;
    }

    .login-right {
      .login-right-shape {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .login-container {
    .login-info {
      width: 80%;
    }
  }
}
