.ta-modal {
  width: 100%;
  z-index: 20 !important;
  & > div {
    & > div {
      width: 530px;
      padding: 15px 24px !important;
      margin: 0 !important;
      background: #ffffff;
      box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
      border-radius: 12px;
      max-height: 90vh !important;
    }
  }
  .modal_title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0;

    span {
      font-weight: 600;
      font-size: 17px;
      line-height: 28px;
      color: #101828;
    }
  }
  .ta-modal-content-scroll {
    padding: 0;
  }
  .subtitle {
    font-weight: 400;
    font-size: 14px;
    color: #475467;
    margin-bottom: 10px;
  }
}

// user modal
.dashboard-user-modal {
  width: 100%;
  z-index: 20 !important;
  & > div {
    & > div {
      // width: 829px !important;
      // height: 721px !important;
      padding: 30px !important;
      margin: 0 !important;
      background: #ffffff;
      box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
      border-radius: 12px;
      max-height: 90vh !important;
    }
  }
  .modal_title {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0;

    span {
      font-weight: 600;
      font-size: 17px;
      line-height: 28px;
      color: #101828;
    }

    .label {
      font-size: 18px;
      color: #177c9a;
      margin-top: 10px;
      margin-bottom: 3px;
    }

    .close-btn {
      position: absolute;
      top: 25px;
      right: 20px;
      cursor: pointer;
    }
  }
  .subtitle {
    font-size: 14px;
    color: #475467;
    text-align: center;
  }

  .ta-modal-content-scroll {
    padding: 0;
    margin-top: 35px;
  }
  .subtitle {
    font-weight: 400;
    font-size: 14px;
    color: #475467;
    margin-bottom: 10px;
  }
}
