.dragger {
  label {
    color: var(--Gray-700, #344054);
    font-size: 12.556px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.938px; /* 142.857% */
  }
  .ant-upload.ant-upload-drag {
    border-radius: 12px;
    border: 1px dashed var(--Primary-Colors-Primary-600, #21afda) !important;
    background: var(--Primary-Colors-Primary-100, #e5f4fd) !important;
  }

  .dragger-content {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    .upload-text {
      color: var(--Primary-Colors-Gray-700, #344054);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
    }
    .upload-hint {
      color: var(--Gray-600, #475467);
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
    }
    .upload-drag-icon {
      display: flex;
      width: 40px;
      height: 40px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      border-radius: 28px;
      border: 6px solid var(--Gray-50, #f9fafb);
      background: var(--Primary-Colors-Primary-100, #e5f4fd);
    }
  }
}
