.main_layout {
  display: flex;
  & > .main_layout__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100vh;
    overflow-y: scroll;
    .header {
      position: sticky;
      top: 0;
      z-index: 8;
      position: absolute;
      top: 0;
      transition: all .5s;
    }
    main{
      transition: all .5s; 
      height: 100vh; 
      }
  }
}
