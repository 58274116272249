.upload-container{
    position: relative;
    input{
        padding-right: 45px;
    }
    .upload-icon{
        color: #1677ff;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 50%;
        height: 100%;
        transform: translateY(-50%);
        z-index: 1;
        font-size: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s;
        border-left: 1px solid #d0d5dd;
        padding-left: 5px;

    }
}