.plan {
  .first_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 26px;
    .title {
      color: var(--font, #344054);
      font-size: 18px;
      display: flex;
      align-items: center;
      gap: 16px;
      font-weight: 500;
      line-height: 28px;
    }
    .subtitle {
      color: var(--font, #344054);
      font-size: 14px;
      font-family: Poppins;
      line-height: 20px;
    }
    .amount_per_month {
      color: #000;
      font-size: 30px;
      font-weight: 500;
      line-height: 38px;
      // text-overflow: ellipsis;
      // overflow: hidden;
      text-align: end;
      @media only screen and (max-width: 602px) {
        font-size: 20px;
        line-height: 18px;
      }

      span {
        font-size: 12px;
        white-space: nowrap;
      }
    }
  }
  .divider {
    height: 1px;
    width: 100%;
    background: var(--stroke, #e4e7ec);
    margin-bottom: 17px;
  }
  .action {
    display: flex;
    justify-content: space-between;
  }
}
