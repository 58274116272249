.slider-antd {
  width: 100%;
  .slider-label {
    color: var(--Gray-700, #344054);
    font-size: 11.812px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.874px; /* 142.857% */

    display: flex;
    align-items: center;
    gap: 10px;
  }
  .ant-slider-mark {
    top: -26px;
  }
  .ant-slider-with-marks {
    bottom: -23px;
  }
}
