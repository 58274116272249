.confirm_modal {
  .MuiPaper-root {
    padding: 30px 50px !important;
  }

  .modal_title {
    display: flex;
    justify-content: center !important;
    align-items: center;

    .close-btn {
      cursor: pointer;
      position: absolute;
      top: 25px;
      right: 20px;
    }
  }

  .content_confirm {
    text-align: center;

    .title {
      margin: 19px 6px;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      color: #f04438;
    }

    .subtitle-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .subtitle {
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        color: #475467;
        width: 300px;
      }
    }
  }
  .action_confirm {
    display: flex;
    justify-content: center;
    gap: 15px;
    width: 100%;
    margin-top: 40px;
    // button {
    //   width: 100%;
    //   display: flex;
    //   justify-content: center;
    //   justify-content: center;

    //   border: 1px solid #d0d5dd;
    //   box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    //   border-radius: 8px;
    //   height: 44px;
    //   padding: 10px 18px;
    //   font-weight: 500;
    //   font-size: 14px;
    //   line-height: 24px;
    //   background: #d92d20;
    //   color: #ffff;
    //   cursor: pointer;
    // }
    // & > :first-child {
    //   color: #344054;
    //   background: #ffffff;
    // }

    .dashboard-cancel-btn {
      width: 210px;
      height: 44px;
      background: #ffffff;
      border: 1px solid #0188f7;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      color: #0188f7;
      transition: all 300ms ease;
      cursor: pointer;

      &:hover {
        background-color: #0188f7;
        color: #ffffff;
      }
    }

    .dashboard-confirm-btn {
      width: 210px;
      height: 44px;
      background: #0188f7;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      color: #ffffff;
      transition: all 300ms ease;
      border: 0 !important;
      cursor: pointer;

      &:hover {
        background: #0188f7;
        filter: brightness(85%);
      }
    }
  }
}
