@use "./components/Input";
@use "./components/Button";

.input {
  width: 100%;
  padding: 12px;
  background-color: white;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  height: 50px;
  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    color: #d0d5dd;
  }
}
