.ant-modal-content {
  width: 500px;
  @media only screen and (max-width: 1060px) {
    width: 100%;
  }
  .ant-modal-body {
    .ant-modal-confirm-body-wrapper {
      //before
      .ant-modal-confirm-body {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .ant-modal-confirm-title {
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: #101828;
          margin-top: 16px;
          margin-bottom: 5px;
        }
        //content
        .ant-modal-confirm-content {
          margin: 0;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #475467;
          margin-bottom: 10px;
        }
        //btns
      }
      .ant-modal-confirm-btns {
        display: flex;
        button {
          width: 100%;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 8px;
          background: #ffffff;
          height: 44px;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #344054;
        }
        & > :last-child {
          background: #d92d20;
          color: #ffff;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        }
      }
    }
  }
}
