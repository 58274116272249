.plan-modal {
  width: 800px !important;

  .ant-modal-content {
    width: 100%;
  }
  .price_item_total {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6.75px;
    border: 0.844px solid var(--stroke, #e4e7ec);
    height: 45px;
    margin-top: 10px;
    min-width: 158px;
    // gap: 5px;
    margin-bottom: -45px;
    width: fit-content;
    .unit {
      color: var(--font, #344054);
      font-size: 10.81px;
      font-style: normal;
      font-weight: 400;
      line-height: 16.874px; /* 156.097% */
      background: #b6dbff;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 9px 0 9px;
      width: 100%;
      border-radius: 6px 0px 0px 6px;
    }

    .price {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      .s_input {
        input {
          margin-top: 11px;
          border: none;
          background-color: transparent;
          width: 100%;
        }
      }
    }
  }
}
.offer_form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  margin-top: 30px;
  .upload {
    display: flex;
    flex-direction: column;
    width: 100%;
    .ant-upload {
      width: 100% !important;
      & > span {
        width: 100%;
        text-align: left !important;
        & > button {
          width: 100% !important;
          text-align: left !important;
          height: 44px;
        }
      }
    }
  }
  .grid_line {
    display: grid;
    justify-content: space-between;
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    @media only screen and (max-width: 760px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .title {
    text-align: center;
    font-size: 15px;
    font-weight: 600;
  }
}
