@mixin usage {
  border-radius: 4.15px;
  border: 0.519px solid #0188f7;
  background: #fff;
  min-width: 90px;
  padding: 5px;
  text-align: center;
  p:first-child {
    color: #344054;
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;
  }
  p:last-child {
    color: #696f79;
    font-size: 10.028px;
    font-weight: 400;
  }
}
.drawer-body {
  .org-users {
    &-list {
      &-header {
        height: 54px;
        background: #f2f4f7;
        border: 1px solid #e4e7ec;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 18px;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #101828;
      }
      .power {
        cursor: pointer;
        &:hover {
          svg {
            path {
              fill: #33a5f1;
            }
          }
        }
      }
    }
  }
  .count-org {
    .key_value {
      border-bottom: 1px solid #ececec;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      p:first-child {
        font-weight: 400;
        font-size: 14px;
        color: #858585;
      }
    }
  }
  .org-consomation {
    &-list {
      &-item {
        display: flex;
        align-items: center;
        padding: 9px 0px;
        justify-content: space-between;
        border-bottom: 1px solid #e4e7ec;
        &:not(:last-child) {
          margin-bottom: 15px;
        }
        div:first-child {
          display: flex;
          align-items: center;
          column-gap: 14px;
          font-size: 16.093px;
        }
        div:last-child {
          @include usage;
        }
      }
    }
  }
}
.usage_user_menu {
  .MuiPaper-root {
    display: flex;
    padding: 10.863px;
    column-gap: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12.534px;
    background: #e6f2fccc;
    .MuiList-padding.MuiMenu-list {
      width: fit-content !important;
    }
  }
  .menu-item {
    border-bottom: 0.836px solid #63a8f1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #344054;
    font-size: 13.447px;
    font-weight: 400;
    column-gap: 15px;
    padding-bottom: 12px;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    &-info {
      display: flex;
      align-items: center;
      column-gap: 14px;
    }
    &-usage {
      @include usage;
    }
  }
}

.org-deatils-spinner {
  text-align: center;
  margin-top: 100px;
}
