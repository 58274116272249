@use "./componets/Charts/Charts";
.dashboard_page {
  display: flex;
  width: 100%;
  padding: 30px;
  gap: 30px;
  flex-direction: column;
  background-color: #fafafb;
  overflow: auto;
  height: 100vh;
  .cards {
    width: 100%;
    flex: 100%;
  }
  .charts {
    display: flex;
    width: 100%;
    gap: 30px;
    @media only screen and (max-width: 1002px) {
      flex-wrap: wrap;
    }
    .chart_plot {
      flex: 3.75;
      background-color: #fff;
      padding: 30px;
      padding-bottom: 30px;
      border-radius: 12px;
      // border: 1px solid #f0f0f0;
      box-shadow: rgba(47, 43, 61, 0.1) 0px 4px 18px 0px;
      background-image: none;
      width: 100%;
    }
    .liquid_plot {
      flex: 1;
      background-color: #fff;
      padding: 30px;
      padding-bottom: 30px;
      border-radius: 12px;
      // border: 1px solid #f0f0f0;
      box-shadow: rgba(47, 43, 61, 0.1) 0px 4px 18px 0px;
      background-image: none;
      width: 100%;
    }
  }
  .cards_details {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    flex-wrap: auto;
  }
}
