.allowed-templates {
  // margin-bottom: 20px;
  .images-group-container {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    height: 300px;
    overflow: auto;
    .images_item {
      display: flex;
    }
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
  }
}
