.plan_features_form {
  display: flex;
  flex-direction: column;

  // .grid-features-form {
  //   display: grid;
  //   grid-template-columns: repeat(2, 1fr);
  //   grid-gap: 30px;
  //   margin-bottom: 20px;
  //   @media only screen and (max-width: 1289px) {
  //     grid-template-columns: repeat(1, 1fr);
  //   }
  // }

  .title {
    margin-bottom: 20px;
  }

  .grid_line {
    display: flex;
    gap: 10px;
    flex-direction: column;

    .disk-slider {
      margin-bottom: 16px;
    }

    .num-user {
      max-height: 50px;

      .s_input {
        width: 100px;
        display: flex;

        .input_antd {
          height: 38px;
        }
      }
    }
  }

  .input_s {
    display: flex;
    gap: 20px;
    align-items: center;
    width: 100%;

    .s_input {
      width: 130px;
      display: flex;
    }

    .input_antd {
      bottom: -6px;
    }

    .price_item {
      display: flex;
      align-items: center;
      border-radius: 6.75px;
      border: 0.844px solid var(--stroke, #e4e7ec);
      height: 45px;
      margin-top: 10px;
      // padding: 0 9px;
      min-width: 138px;
      gap: 5px;

      .unit {
        color: var(--font, #344054);
        font-size: 10.81px;
        font-style: normal;
        font-weight: 400;
        line-height: 16.874px;
        /* 156.097% */
        background: #b6dbff;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 9px 0 9px;
        width: 100%;
        border-radius: 6px 0px 0px 6px;
      }

      .price {
        color: var(--font, #344054);
        font-size: 11.812px;
        font-style: normal;
        font-weight: 500;
        line-height: 16.874px;
        /* 142.857% */
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90%;
        height: 100%;
        border: none;
      }
    }
  }

  .ldap_checkbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px;
    border-radius: 8px;
    border: 1px solid #e2e5e9;
    margin-bottom: 16px;
    max-height: 50px;

    .title_item {
      display: flex;
      align-items: center;
      gap: 9px;

      p {
        color: var(--gray-700, #344054);
        font-size: 12.556px;
        font-style: normal;
        font-weight: 500;
        line-height: 17.938px;
        /* 142.857% */
      }
    }

    .switch {
      display: flex;
      align-items: center;
      gap: 8px;

      .status-active {
        color: var(--Prim-Blue, #0188f7);
        font-size: 13.161px;
        font-style: normal;
        font-weight: 500;
        line-height: 18.801px;
        /* 142.857% */
      }

      .status-inactive {
        color: #afb4bd;
        font-size: 13.161px;
        font-style: normal;
        font-weight: 500;
        line-height: 18.801px;
        /* 142.857% */
      }
    }
  }
}

#components-slider-demo-mark h4 {
  margin: 0 0 16px;
}

#components-slider-demo-mark .ant-slider-with-marks {
  margin-bottom: 44px;
}