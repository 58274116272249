@use "./assets/styles/base/base";
// Layouts
@use "./layouts/MainLayout";
@use "./layouts/GuestLayout";
@use "./layouts/AdminLayout";

// Components
@use "./components/Sidebar";
@use "./components/Header";
@use "./components/Menu";
@use "./components/Progress";
@use "./components/HamburgerMenuTrigger";
@use "./components/Backdrop";
@use "./components/NotFound";
@use "./components/Navigation";
@use "./components/Input";
@use "./components/Status";

@use "./components/InputFields";
@use "./components/Modals";
@use "./components/SplashScreen";

//pages
@use "./features/Authentication/login";
@use "./features/Authentication/components/Card";
@use "./features/Authentication";
@use "./features/Authentication/register";
@use "./features/Billing";
@use "./features/UsersPerOrg";
@use "./features/ActiveSessions";
@use "./features/Users/Users";

//profile
@use "./features/Settings";
@use "./layouts/SettingLayout";
@use "./features/Dashboard";

//orgs
@use "./features/Organizations";
@use "./features/Offers";

//admin
@use "./components/Table";
@use "./components/ViewTitle";
@use "./components/Pagination";
@use "./features/UserGroup";
@use "./features/ImageGroup";
@use "./components/Tabs";

.view_container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
  background-color: white;
}
