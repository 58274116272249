.image_group_form {
  .images {
    margin-top: 30px;
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 0 23px;
    max-height: 250px;
    overflow: auto;
    &_item {
      display: flex;
      column-gap: 10px;
      &-label {
        text-align: center;
        img {
          width: 35px;
          height: 35px;
        }
        p {
          font-weight: 500;
          font-size: 10px;
          inline-size: 50px;
          overflow-wrap: break-word;
          text-align: center;
        }
      }
    }
  }

  .ant-checkbox-wrapper {
    span {
      padding: 0 !important;
    }
  }
}
