.form_tag {
  display: flex;
  align-items: center;
  gap: 10px;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    // border: none;
    background: none;
  }
}
