.billing_feature {
  padding: 35px 60px;
  overflow-x: hidden;

  @media only screen and (max-width: 1559px) {
    padding: 35px 40px;
  }
  @media only screen and (max-width: 602px) {
    padding: 35px 20px;
  }
  .title_page {
    margin-bottom: 34px;
    p {
      color: var(--prim-blue, #0188f7);
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
    }
    span {
      color: var(--primary-colors-gray-700, #344054);
      font-size: 16px;
      line-height: 24px;
    }
    .download_all_btn {
      border-radius: 8px;
      border: 1px solid var(--secondary-colors-blue-600, #1570ef);
      background: var(--prim-blue, #0188f7);
      display: flex;
      padding: 9px 26px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: var(--primary-colors-gray-0, #fafafa);

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      cursor: pointer;
    }
  }
  .content_plan {
    display: flex;
    align-items: center;
    // justify-content: center;
    gap: 40px;
    margin-bottom: 50px;
    @media only screen and (max-width: 1289px) {
      flex-wrap: wrap;
      margin-bottom: 30px;
    }
    .plan {
      width: 100%;
    }
    .pay_method {
      width: 100%;
    }
  }
  .card_pay {
    border-radius: 8px;
    border: 1px solid var(--stroke, #e4e7ec);
    padding: 20px 20px 17px 20px;
    min-height: 230px;
  }
  //billing history

  .billing_history {
    .title_page {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 26px;
    }
    .table_hisory {
      overflow: auto;
    }
  }
}
