.navigation__container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  width: 100%;

  & > .navigation_link {
    display: flex;
    gap: 14px;
    background: #ffffff;
    width: 100%;
    height: 50px;
    border-radius: 8px;
    align-items: center;
    padding: 12px;
    transition: all 300ms ease;
    & > svg {
      stroke: #667085;
    }
    & > .navigation_link__label {
      font-weight: 400;
      font-size: 16px;
      color: #667085;
    }
    &:hover {
      background: #f2f4f7;
    }
    &.active {
      background: #0188f7;

      & > svg {
        stroke: #ffffff;
      }
      & > .navigation_link__label {
        color: #ffffff;
      }
    }
  }
}
