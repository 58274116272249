@use "./components/InviteUserForm/InviteUserForm";
@use "./components/InviteUserListModal";

.org_page {
  display: flex;
  flex-direction: column;
  .org {
    margin-bottom: 60px;
  }
  .active-sessions_per_org {
    margin-bottom: 60px;
  }
}
