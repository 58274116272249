.sessions-table {
  color: #101828;
  font-size: 14px;
  font-weight: 500;
  .session-name {
    display: flex;
    align-items: center;
    column-gap: 5px;
    span {
      display: block;
      min-width: 40px;
      min-height: 40px;
      max-width: 40px;
      max-height: 40px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #eee;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .target-org-path {
    margin: 0 auto;
    width: 100%;
    margin-left: 20px;
    img {
      width: 25px;
    }
  }
}
