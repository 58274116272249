.monthly-yearly-price {
  margin-bottom: 20px;
  .flex-fields {
    display: flex;
    width: 100%;
    gap: 20px;
    .f-monthly-price-field {
      width: 100%;
      // display: flex;
      // gap: 20px;
    }
  }

  .help-message {
    display: flex;
    align-items: center;
    gap: 10px;
    p {
      font-size: 12px;
    }
  }
  .daily-price {
    flex-direction: column;
  }
  .every-period {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 100%;
    height: 44px;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    padding: 0 10px;
    margin-bottom: 20px;
    .s_input {
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      input {
        border-radius: 0px;
        border: none;
        height: 40px !important;
        border-left: 1px solid #d0d5dd;
        border-right: 1px solid #d0d5dd;
      }
    }
  }
  .amount-daily {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .switch-free-plan {
    margin-bottom: 30px;
  }
  label {
    color: var(--Gray-700, #344054);
    font-size: 12.556px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.938px;
  }
}
