.profile {
  &_content {
    margin-top: 35px;
    & > div {
      border: 1px solid #e4e7ec;
      border-radius: 8px;
      padding: 15px 20px;
      margin-bottom: 30px;
    }
  }
  &_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .user-info {
      display: flex;
      align-items: center;
      column-gap: 35px;

      &-photo {
        width: 103px;
        height: 103px;
        border-radius: 50%;
        overflow: hidden;

        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        & > div,
        img {
          width: 100%;
          height: 100%;
        }
      }
      p {
        font-weight: 400;
        font-size: 15px;
        color: #667085;
        line-height: 28px;
      }
      p:nth-child(1) {
        font-weight: 500;
        color: #000000;
      }
    }
  }

  .profile_personal_info {
    .personal-info-header {
      font-weight: 500;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 35px;
    }
    .personal-info-content {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      justify-content: space-between;
      & > div {
        width: calc(50% - 15px);
        min-width: 200px;
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 30px;
        }
        p:first-child {
          color: #667085;
        }
      }
    }
  }
  .profile_history {
    &-header {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 35px;
    }
    .grid-container {
      display: grid;
      grid-template-columns: repeat(4, minmax(250px, 1fr));
      grid-gap: 10px;
      overflow: auto;
    }

    .grid-item {
      background-color: #fff;
      padding: 20px;
      text-align: center;
      height: 44px;
      display: flex;
      align-items: center;
      border: 0px solid #e4e7ec;
      border-width: 0px 1px 1px 0px;
      box-shadow: -1px 0px 9px -10px rgba(0, 0, 0, 0.47);
    }
  }

  .edit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    font-weight: 400;
    font-size: 15px;
    color: #344054;
    padding: 10px;
    border: 1px solid #e4e7ec;
    border-radius: 8px;
    cursor: pointer;
  }

  #update-my-pic {
    display: none;
  }
}
